<script>
  import {
    StarFilledRound,
    AmazonStore,
    ApplePlayStore,
    GooglePlayStore,
  } from '@/components/icons'

  import Carousel from '@/components/Carousel.svelte'
  import CarouselItem from '@/components/CarouselItem.svelte'

  import ReadMore from '../../ReadMore.svelte'

  export let darkTheme
  const comments = [
    {
      name: 'Neil Ski',
      date: '20 de abril',
      title: '¡Consigue uno en cuanto puedas!',
      description:
        'Tienen rebajas, pero de todos modos hazte con uno aunque no esté de oferta. ¡¡¡Puedes escanear el coche de tus amigos y mostrarles lo bueno que es este pequeño dispositivo!!! Pero cuando realmente ahorras dinero y ganas en tranquilidad es al usarlo en tus propios vehículos. Puedes poner tu kilometraje y activar las alertas para que te avise cuando tengas que cambiar el aceite, y lo más importante es el mantenimiento preventivo. ¡¡¡La segunda vez que lo uses te darás cuenta de que hiciste una de las mejores compras de tu vida!!! He usado mucho este pequeño dispositivo y me ha servido sin tener el paquete completo, ¡¡¡pero ahora voy a actualizarlo para conseguir todos los servicios!!! Debería registrar esta frase, ¡¡¡simplemente cómpralo, no te arrepentirás!!!',
      icon: ApplePlayStore,
      source: 'Apple App Store',
      url: 'https://apps.apple.com/us/app/fixd-obd2-scanner/id957168651?adb_sid=be45ef67-943b-4e85-ad7d-7020fa7e2b0b',
      type: 'apple',
    },
    {
      name: 'Fernando Diaz',
      date: '16 de junio, 2023',
      title: null,
      description: 'Lo mejor de lo mejor',
      icon: GooglePlayStore,
      source: 'Google Play',
      url: 'https://play.google.com/store/apps/details?id=com.fixdapp.two&hl=en_US&reviewId=f151c900-1634-4b1e-954d-67f73c23de95&adb_sid=be45ef67-943b-4e85-ad7d-7020fa7e2b0b',
      type: 'google',
    },
    {
      name: 'onenonly8714',
      date: '25 de mayo, 2023',
      title: 'Reseña para mi Honda Accord LX',
      description:
        'Bueno, soy mujer y me gusta mantener mis coches al día con todo, porque siempre llevo a mis hijos o a mis amigos en el coche y me encanta ir a sitios. Cuando compré el dispositivo FIXD, todo cambió por completo. No tuve que pedirle a ningún familiar que me ayudara con el coche. Me resultó muy fácil conseguirlo y configurarlo yo misma, y lo explica todo perfecto para que cualquiera lo entienda. Definitivamente recomiendo a todo el mundo que invierta en una de estas cosas. No te arrepentirás💯',
      icon: ApplePlayStore,
      source: 'Apple App Store',
      url: 'https://apps.apple.com/us/app/fixd-obd2-scanner/id957168651?adb_sid=be45ef67-943b-4e85-ad7d-7020fa7e2b0b',
      type: 'apple',
    },
    {
      name: 'Cody Douglas',
      date: '25 de febrero, 2023',
      title: 'Estoy absolutamente encantado con esto 🙌',
      description:
        'Sin duda el mejor producto para tu coche suv camioneta lo que sea tengo un chevy 2001 y lo dejo en mi coche todo el día es el mejor producto para tu coche si tienes un problema 😉 recomiendo muchísimo este producto por encima de todos los otros 5 estrellas seguro y es tan barato ahora lo compré cuando costaba 35 ahora está a 25',
      icon: AmazonStore,
      source: 'Amazon',
      url: 'https://apps.apple.com/us/app/fixd-obd2-scanner/id957168651?adb_sid=be45ef67-943b-4e85-ad7d-7020fa7e2b0b',
      type: 'amazon',
    },
  ]

  $: currentType = 'all'
  $: filteredComments =
    currentType === 'all'
      ? comments
      : comments.filter((x) => x.type === currentType)

  const filterComments = (type) => {
    currentType = type
  }

  const filterButtons = [
    {
      type: 'all',
      icon: null,
      text: 'All Reviews',
      rating: 4.6,
    },
    {
      type: 'apple',
      icon: ApplePlayStore,
      text: 'Apple App Store',
      rating: 4.6,
    },
    {
      type: 'amazon',
      icon: AmazonStore,
      text: 'Amazon',
      rating: 4.6,
    },
    {
      type: 'google',
      icon: GooglePlayStore,
      text: 'Google Play',
      rating: 4.6,
    },
  ]
</script>

<div class="rounded max-w-[900px] mx-auto">
  <div class="bg-green rounded-t-lg py-0.5 font-inter text-sm">
    <div class="max-w-screen-md mx-auto">
      <Carousel
        showPagination={false}
        showArrows={false}
        arrowColor="white"
        splideOptions={{
          classes: 'flex',
          perPage: 4,
          width: '768px',
          padding: 32,
          breakpoints: {
            768: {
              perPage: 2,
              fixedWidth: 170,
              gap: '16px',
              arrows: true,
            },
          },
        }}
      >
        {#each filterButtons as button}
          <CarouselItem>
            <div
              class="flex items-center justify-center"
              on:click={() => filterComments(button.type)}
              on:keydown={() => filterComments(button.type)}
              role="button"
              tabindex="-1"
            >
              <div
                class="py-2 border-b-2 flex items-center gap-3"
                class:border-transparent={currentType !== button.type}
              >
                {#if button.icon}
                  <svelte:component
                    this={button.icon}
                    color="black"
                    size="md"
                  />
                {/if}
                <p class="text-white">
                  {button.text}
                  <span class="font-semibold">{button.rating}</span>
                </p>
              </div>
            </div>
          </CarouselItem>
        {/each}
      </Carousel>
    </div>
  </div>
</div>

<div class="space-y-5 max-w-[900px] mx-auto">
  {#each filteredComments as comment}
    <div class="rounded p-6 {darkTheme ? 'bg-[#000000]' : 'bg-[#f3f3f3]'}">
      <header class="text-left">
        <div class="mb-3">
          <a
            href={comment.url}
            class="font-semibold text-sm no-underline hover:underline text-black"
            >{comment.name}</a
          >
          <p class="text-xs">{comment.date}</p>
        </div>
        <div class="flex mb-2">
          {#each new Array(5) as _}
            <StarFilledRound color="yellow" size="md" />
          {/each}
        </div>
      </header>
      <div class="text-center leading-6 mb-3">
        {#if comment.title}
          <p class="font-semibold">{comment.title}</p>
        {/if}
        <p class:text-left={!comment.title}>
          <ReadMore text={comment.description} length={325} />
        </p>
      </div>
      <a
        class="text-left no-underline group"
        href={comment.url}
        target="_blank"
        rel="noreferrer"
      >
        <div class="flex items-center">
          <svelte:component this={comment.icon} color={'green'} size="xl" />
          <div class="leading-5 text-center ml-3">
            <p class="text-grey text-xs">Posted on</p>
            <p class="text-green text-sm group-hover:underline">
              {comment.source}
            </p>
          </div>
        </div>
      </a>
    </div>
  {/each}
</div>
