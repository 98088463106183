<script>
  import Section from './widgets/Section.svelte'

  import StaticReviews from './widgets/StaticReviews.svelte'
  import StaticReviewsSpanish from './widgets/StaticReviewsSpanish.svelte'
  import ReviewYoutubeLinks from './widgets/ReviewYoutubeLinks.svelte'

  export let backgroundColor = 'light-grey'
  export let darkTheme = false
  export let language = 'en'
</script>

<Section verticalPadding="short" center="true" background={backgroundColor}>
  <slot name="header">
    <h3 class="text-xl font-normal mb-2" id="live_reviews">
      Don't Just Take Our Word For It...
    </h3>
    <h1 class="font-semibold md:font-bold text-30 mt-0">
      What Are Other Drivers Saying about FIXD?
    </h1>
  </slot>

  <div class="my-4">
    <ReviewYoutubeLinks {darkTheme} />
  </div>
  {#if language === 'es'}
    <StaticReviewsSpanish {darkTheme} />
  {:else}
    <StaticReviews {darkTheme} />
  {/if}
</Section>
